.industry-donut-chart-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 304px;
  
 
}


