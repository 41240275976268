.industry-container {
 
  margin-bottom: 35px;
  margin-left: 25px;
  margin-right: 25px;
  width: 378px;
  height: 83px;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0000003B;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.industry-color-circle {
  width: 31px;
  height: 31px;
  margin-left: 16px;
  margin-right: auto;
/* UI Properties */
  background: #714595 0% 0% no-repeat padding-box;
  border-radius: 32px;

}

.industry-content {
  display: flex;
  
  
  flex-direction: column;
  width: 315px;
  display: flex;
  
  justify-content: center;
  flex-direction: column;
  gap: 15px;
}

.industry-text {
  display: flex;
  align-items: center;
  /* justify-content: center; */
 
  flex-direction: row;
}

.industry-name {
  
  font-size: 16px;
  font-family: graphik-medium;
}

.industry-percentage {
  font-size: 14px;
  font-family: graphik-medium;
  color: #CCCCCC;
  margin-left: auto;
  margin-right: 32px;
}

.industry-percentage-track {
  width: 280px;
height: 8px;
/* UI Properties */
background: #EAEAEA 0% 0% no-repeat padding-box;
box-shadow: 0px 1px 0px #FFFFFF0B;
border-radius: 18px;
opacity: 1;
}

.industry-percentage-bar {

height: 8px;
/* UI Properties */
background: #714595 0% 0% no-repeat padding-box;
border-radius: 18px;
opacity: 1;
}