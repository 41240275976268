.main-login {
  width: 100vw;
  height: 100%;
  padding-bottom: 150px;
  display: flex;
  align-items: center;
 
  flex-direction: column;
}

.logo-box-login {
  overflow: visible;
  width: 50vw;
  /* height: 153px; */
  margin-top: -3vh;
  margin-bottom: -3vh;
  margin-left: auto;
  margin-right: auto;
}

.small-text-row-login {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 3em;

}

.small-text-login {
  font-size: 1em;
  font-family: graphik-regular;
  
  color: #8A8A8F;
  margin-right: 1em;
}

.green-check-inbox {
  height: 20px;
  width: 20px;
}
.small-text-left-row-login {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 10px;
  margin-left: 1em;
}

.button-container-login {
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

}