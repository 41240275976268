.main-6 {

  height: 100vh;
 width: 100vw;
  

  display: flex;
  align-items: center;
 
  flex-direction: column;
}
.main-2 {
  display: flex;
  align-items: center;
  justify-content: center;
  
}



.content-6 {
  width: 85vw;
  /* padding: 200px; */
  display: flex;
  align-items: center;
  margin-top: 20px;
  flex-direction: column;
}

.small-text-6 {
  font-size: 1.4em;
  font-family: graphik-regular;
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-000000);
  text-align: left;
  line-height: 150%;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  padding-top: 10px;
  padding-bottom: 10px;
}
.large-text-6 {
  font-size: 1.9em;
  font-family: graphik-bold;
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-000000);
  text-align: left;
  line-height: 130%;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;

}



.cloud-logo {
  margin: 0;
  padding: 0;
  overflow: visible;
  /* margin-right: auto;
  margin-left: auto; */
 
  
}

.logo-box {
  
  /* position: relative; */
  /* width: 40vw; */
  /* height: 15vh; */
  /* margin-top: -6.5vh;
  margin-bottom: -6vh;
  margin-right: auto;
  margin-left: auto; */
  overflow: visible;
  width: 50vw;
  /* height: 153px; */
  margin-top: -3vh;
  margin-bottom: -3vh;
  margin-left: auto;
  margin-right: auto;
}

.button-container-6 {
margin-top: 2em;
}

.nav-container-6 {
  margin-top: 2em;
 margin-bottom: 5vh;
  display: flex;
  align-items: center;
  justify-content: center;
 z-index: 1;

}


.nav-dot-left-6 {
  height: 13px;
  width: 13px;
  background-color: #2436E7;
  border-radius: 50%;
  display: inline-block;
  margin-left: 10px;
}

.nav-dot-right-6 {
  height: 13px;
  width: 13px;
  background-color: #C5C5C5;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;
}