@tailwind base;
@tailwind components;
@tailwind utilities;
@import "/src/Fonts/Font.css";


.button-1 {
    padding: 0;
    border: none;
    background: none;
    width: 30em;
    max-width: 100%;
    height: 2.25em;
    /* UI Properties */
    background: var(--unnamed-color-2436e7) 0% 0% no-repeat padding-box;
    background: #2436E7 0% 0% no-repeat padding-box;
    border-radius: 30px;
    opacity: 1;
    /* font-family: graphik-medium; */
    font-family: graphik-regular;
    font-size: 1.25em;
    color: var(--unnamed-color-ffffff);
    text-align: center;
    cursor: pointer;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
  }
  
  .button2 {
    padding: 0;
    border: none;
    background: none;
    width: 93vw;
    max-width: 500px;
    height: 2.5em;
    cursor: pointer;
    /* UI Properties */
    background: var(--unnamed-color-2436e7) 0% 0% no-repeat padding-box;
    background: #2436E7 0% 0% no-repeat padding-box;
    border-radius: 30px;
    opacity: 1;
    /* font-family: graphik-medium; */
    font-family: graphik-regular;
    font-size: 1.2em;
    color: var(--unnamed-color-ffffff);
    text-align: center;
   
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
  }
  

  .text-link {
    color: inherit;
    text-decoration: inherit;
}



body {
  margin: 0;
  padding: 0;
}
