
.background-5 {
  
  

  background-image: url("../Media/imgpsh_anim.jpeg");
  background-size: auto 100%;
  height: 749px;
  background-repeat: no-repeat;
  /* Center and scale the image nicely */
  
  /* background-repeat: no-repeat;
  background-size: cover; */
  object-fit: contain;
  /* Preserve aspet ratio */
  background-size: cover;
  width: 100%;

  display: flex;
  
  
  flex-direction: column;
  cursor: pointer;
  z-index: 0;

}

.upper-5{
  width: 100vw;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 1;
  background-color: #56BFE8;

}
.blume-text-container {
  overflow: visible;
  width: 100%;
  height: auto;
  margin-top: -10vh;
  
  margin-left: auto;
  margin-right: auto;
}
.blume-text {
  margin: 0;
  padding: 0;
  overflow: visible;
  margin-right: auto;
  margin-left: auto;
  
}

.background-image-5 {
z-index: 0;
}


.nav-container-5 {
  position: sticky;
 margin-top: auto;
  margin-bottom: 10vh;
  margin-left: auto;
  margin-right: auto;
  /* display: flex;
  align-items: center;
  justify-content: center; */
  z-index: 1;
 

}


.nav-dot-left-5 {
  height: 13px;
  width: 13px;
  background-color: #2436E7;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
}

.nav-dot-right-5 {
  height: 13px;
  width: 13px;
  background-color: #C5C5C5;
  border-radius: 50%;
  display: inline-block;
  margin-left: 10px;
  cursor: pointer;
}