.main-most-active {
  margin-top: 52px;
  display: flex;
  
  flex-direction: column;
}

.big-text-most-active {
  padding-left: 26px;
  color: #2436E7;
  font-size: 20px;
  font-family: graphik-medium;
  margin-bottom: 16px;
  text-align: left;
}

.top-three-active {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 50px;
}

.container-most-active {
 
  margin-bottom: 35px;
  
  width: 90vw;
  height: 83px;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0000003B;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.color-circle-most-active {
  width: 31px;
  height: 31px;
  margin-left: 16px;
  margin-right: auto;
/* UI Properties */
 
  border-radius: 32px;

}
.content-most-active {
  display: flex;
  
  
  flex-direction: column;
  width: 315px;
  display: flex;
  margin-left: 3vw;
  justify-content: center;
  flex-direction: column;
  gap: 9px;
}

.text-most-active {
  display: flex;
  align-items: center;
  /* justify-content: center; */
 
  flex-direction: row;
}

.name-most-active {
  
  font-size: 16px;
  font-family: graphik-medium;
}

.share-price-most-active{
  font-size: 14px;
  font-family: graphik-medium;
  color: #000000;
  margin-left: auto;
  margin-right: 32px;
}

.ticker-most-active {
  font-size: 14px;
  font-family: graphik-regular;
}

.percentage-most-active {
  font-size: 14px;
  font-family: graphik-medium;
  color: #8dc63f;
  margin-left: auto;
  margin-right: 32px;
}

.percentage-track-most-active {
  width: 280px;
height: 8px;
/* UI Properties */
background: #EAEAEA 0% 0% no-repeat padding-box;
box-shadow: 0px 1px 0px #FFFFFF0B;
border-radius: 18px;
opacity: 1;
}

.percentage-bar-most-active {

height: 8px;
/* UI Properties */
background: #714595 0% 0% no-repeat padding-box;
border-radius: 18px;
opacity: 1;
}