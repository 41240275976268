@font-face {
  font-family: 'graphik-medium';
  src: local('graphik-medium'),
       url('./Graphik-Medium-Web.woff') format('woff');
}
@font-face {
  font-family: 'graphik-regular';
  src: local('graphik-regular'),
       url('./Graphik-Regular-Web.woff') format('woff');
}

@font-face {
  font-family: 'graphik-semibold';
  src: local('graphik-semibold'),
       url('./Graphik-Semibold-Web.woff') format('woff');
}
@font-face {
  font-family: 'graphik-bold';
  src: local('graphik-bold'),
       url('./Graphik-Bold-Web.woff') format('woff');
}