.main-11 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 926px;
  width: 100vw;
  margin: 0;
  padding: 0;
}
.content-11 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 38px;
}
.large-text-11 {
  font-size: 24px;
  font-family: graphik-Bold;
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-000000);
  text-align: left;
  line-height: 130%;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.label-text-11 {
  font-family: graphik-medium;
  font-size: 18px;
  color: #2436e7;
  margin-top: 36px;
}

.select1 {
  top: 180px;
left: 39px;
width: 350px;
/* height: 48px; */
/* UI Properties */
background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
background: #FFFFFF 0% 0% no-repeat padding-box;
border: 1px solid #C4C4C4;
border-radius: 24px;
opacity: 1;
}
.dropdown {
width: 350px;
margin: 100 auto;
position: relative;
user-select: none;
padding: 0;
margin: 0;
}


.dropdown-button-active {
  padding: 5px 10px;
  background: #fff;
  height: 35px;
  
  border: 1px solid #C4C4C4;
  border-radius: 24px 24px 0px 0px;
  opacity: 1;
  color: #333;
  font-family: graphik-medium;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding-bottom: 0 !important;

}

.dropdown-arrow-active{
  border: solid black;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}
.dropdown .dropdown-button-active {

  /* Layout Properties */
 padding: 10px 26px 26px 20px;
 

 background: #fff;
 border: 1px solid #C4C4C4;
 border-radius: 24px 24px 0px 0px;
 color: #C5C5C5;
 font-family: graphik-medium;

 font-size: 14px;
 display: flex;
 align-items: center;
 justify-content: space-between;
 cursor: pointer;
 }

.dropdown .dropdown-button-selected {

 /* Layout Properties */
 padding: 16px 26px 16px 20px;
background: #fff;
border: 1px solid #C4C4C4;
border-radius: 24px;
/* height: 48px; */
color: #333;
font-family: graphik-medium;
font-size: 14px;
display: flex;
align-items: center;
justify-content: space-between;
cursor: pointer;

}

.dropdown-arrow{
  border: solid black;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.dropdown .dropdown-content {
  position: absolute;
  top: 98%;
  width: 99.4%;
  height: 170px;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 4px #00000014;
  border: 1px solid #C4C4C4;
  border-radius: 0px 0px 24px 24px;
  opacity: 1;
  overflow: scroll;
  z-index: 2;
}
::-webkit-scrollbar {
  width: 0;  
  background: transparent;  
}
.dropdown-item {
  height: 38px;
  font-size: 14px;
  font-family: graphik-medium;
  margin: 16px 0px 16px 20px;
  cursor: pointer;
  z-index: 1;
}




.interest-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 18px;
}

.default-interest-11 {
  width: 96px;
  height: 31px;
  margin-top: auto;
  margin-bottom: auto;
  border: 1px solid var(--unnamed-color-2436e7);
  border: 1px solid #2436E7;
  border-radius: 16px;
  color: #2436E7;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-family: graphik-medium;
  cursor: pointer;
}

.selected-interest-11 {

width: 96px;
height: 31px;
background: var(--unnamed-color-2436e7) 0% 0% no-repeat padding-box;
background: #2436E7 0% 0% no-repeat padding-box;
border: 1px solid var(--unnamed-color-2436e7);
border: 1px solid #2436E7;
border-radius: 16px;
color: #FFFFFF;
opacity: 1;
display: flex;
justify-content: center;
align-items: center;
font-size: 13px;
font-family: graphik-medium;
cursor: pointer;
}



.low-risk-select-11 {
 
  width: 350px;
  height: 32px;
  /* UI Properties */
  
  background-color: #7676801F;
  border: 0.5px solid #0000000A;
  border-radius: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  
  align-items: center;
  
  }

  .low-thin-line-11 {
    border-left: 1px solid #C4C4C4;
    height: 13px;
    margin-right: 24px;
  }


.low-risk-pill-11 {
  width: 117px;
height: 28px;
/* UI Properties */
font-size: 14px;
margin-right: auto;
margin-left: 1px;
color: #2436e7;
background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 8px #0000001F;
border: 0.5px solid #0000000A;
border-radius: 30px;
display: flex;
justify-content: center;
align-items: center;
font-size: 13px;
font-family: graphik-medium;


}
.low-risk-medium-11 {
  font-size: 13px;
  font-family: graphik-medium;
  margin-left: auto;
  margin-right: 36px;
  cursor: pointer;
  
}

.low-risk-high-11 {
  font-size: 13px;
  font-family: graphik-medium;
  margin-left: auto;
  margin-right: 43px;
  cursor: pointer;
  
}


   

.mid-risk-select-11 {
 
  width: 350px;
  height: 32px;
  /* UI Properties */
  
  background-color: #7676801F;
  border: 0.5px solid #0000000A;
  border-radius: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  
  align-items: center;
  
  
  }

  .mid-thin-line-11 {
    border-left: 1px solid #C4C4C4;
    height: 13px;
    margin-right: 24px;
  }


.mid-risk-pill-11 {
  width: 117px;
height: 28px;
/* UI Properties */
font-size: 14px;
margin-right: auto;
margin-left: auto;
color: #2436e7;
background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 8px #0000001F;
border: 0.5px solid #0000000A;
border-radius: 30px;
display: flex;
justify-content: center;
align-items: center;
font-size: 13px;
font-family: graphik-medium;


}
.mid-risk-low-11 {
  font-size: 13px;
  font-family: graphik-medium;
  margin-left: 47px;
  margin-right: auto;
  cursor: pointer;
}

.mid-risk-high-11 {
  font-size: 13px;
  font-family: graphik-medium;
  margin-left: auto;
  margin-right: 43px;
  cursor: pointer;
}




.high-risk-select-11 {
 
  width: 350px;
  height: 32px;
  /* UI Properties */
  
  background-color: #7676801F;
  border: 0.5px solid #0000000A;
  border-radius: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  
  align-items: center;
  }

  .high-thin-line-11 {
    border-left: 1px solid #C4C4C4;
    height: 13px;
    margin-left: 24px;
  }


.high-risk-pill-11 {
  width: 117px;
height: 28px;
/* UI Properties */
font-size: 14px;
margin-right: 1px;
margin-left: auto;
color: #2436e7;
background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 8px #0000001F;
border: 0.5px solid #0000000A;
border-radius: 30px;
display: flex;
justify-content: center;
align-items: center;
font-size: 13px;
font-family: graphik-medium;

}
.high-risk-medium-11 {
  font-size: 13px;
  font-family: graphik-medium;
  margin-left: 36px;
  margin-right: auto;
  cursor: pointer;
}

.high-risk-low-11 {
  font-size: 13px;
  font-family: graphik-medium;
  margin-left: 47px;
  margin-right: auto;
  cursor: pointer;
}

.goal-11 {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;
 
  color: #898B8D;
  font-size: 14px;
  font-family: graphik-regular;
}

.goal-switch-11 {
  margin-left: auto;
  margin-top: 8.5px;
  margin-bottom: 8.5px;
  margin-right: 1.5em;

}