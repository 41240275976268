

.dropdown-leaderboard {
width: 40vw;
margin: 100 auto;
position: relative;
user-select: none;
padding: 0;
margin: 0;
}


.dropdown-button-active-leaderboard {
  padding: 5px 10px;
  background: #fff;
  height: 29px;
  
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 7px 7px 0px 0px;
  color: #333;
  font-family: graphik-regular;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding-bottom: 0 !important;

}

.dropdown-arrow-active-leaderboard{
  border: solid #CCCCCC;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}
.dropdown-leaderboard .dropdown-button-active-leaderboard {

  
  padding: 0px 12px 12px 12px;
 
  margin-bottom: 30px;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 7px 7px 0px 0px;
  font-family: graphik-regular;

  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
 }

.dropdown-leaderboard .dropdown-button-selected-leaderboard {

 /* Layout Properties */
 padding: 8px 12px 8px 12px;
 background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
 background: #FFFFFF 0% 0% no-repeat padding-box;
 box-shadow: 0px 3px 6px #00000029;
 border-radius: 7px;
/* height: 48px; */
color: #333;
font-family: graphik-regular;
font-size: 14px;
display: flex;
align-items: center;
justify-content: space-between;
cursor: pointer;

}

.dropdown-arrow-leaderboard{
  border: solid #CCCCCC;
  border-width: 0 1.33px 1.33px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.dropdown-leaderboard .dropdown-content-leaderboard {
  position: absolute;
  top: 98%;
  width: 100%;
  height: 170px;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
 background: #FFFFFF 0% 0% no-repeat padding-box;
 box-shadow: 0px 3px 6px #00000029;
 border-radius: 7px;

  border-radius: 0px 0px 7px 7px;
  opacity: 1;
  overflow: scroll;
  z-index: 1;
}
::-webkit-scrollbar {
  width: 0;  
  background: transparent;  
}
.dropdown-item-leaderboard {
  height: 38px;
  font-size: 14px;
  font-family: graphik-regular;
  margin: 16px 0px 16px 20px;
  cursor: pointer;
}

