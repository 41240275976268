

.main-2 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin: 0; 
  height: 100vh; 
  overflow: hidden
}
.left-side-2 {
 
  width: 50vw;
  height: 100vh;
  position: relative;
  /* position: absolute; */
  /* z-index: -1;
  left: 0; */
}
.left-image-2{ 
  width: 50vw;
  height: 100vh;
  object-fit: cover;
}

.blume-text-logo-2 {
  position: absolute;
  font-size: 4.5em;
  font-family: graphik-semibold;
  color: white;
  margin-top: .2em;
  padding-left: .2em;
  z-index: 1;
  left: 0;
  top: 0;
  }

.right-side-2 {
  min-width: 600px;
  background-color: white;
  width: 50vw;
  height: 100vh;
  position: sticky;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* z-index: 1;
  right: 0; */
}


.right-content-2 {
  width: 30vw;

}

.small-text-2 {
  font-size: 1.25em;
  font-family: graphik-regular;
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-000000);
  text-align: left;
  line-height: 150%;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  padding-top: 3vh;
  padding-bottom: 5vh;
}
.large-text-2 {
  font-size: 2em;
  font-family: graphik-semibold;
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-000000);
  text-align: left;
  line-height: 110%;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;

}

.button-text-2 {
  font-size: 26px;
  font-family: Roboto;
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-000000);
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  padding-top: 5vh;
  padding-bottom: 5vh;
}

.cloud-logo-2 {
  
  z-index: -1;
  position: sticky;
  width: 300px;
  height: auto;
  /* overflow: hidden; */
  padding-top: 1em;
  
  
}

.logo-box-2 {
  margin-left: auto;
  margin-right: auto;
  width: 25vw;
  height: 25vh;
  padding-bottom: 15vh;
  margin-top: -15vh;
  padding-top: 1vh;
}
