.main-inbox {
  width: 100vw;
  height: 926px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.large-text-inbox {
  font-size: 22px;
  font-family: graphik-medium;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  margin-bottom: 21px;
}
.select-inbox{
  margin-left: auto;
  margin-right: auto;
}

.content-container-inbox {
  display: flex;
  
  justify-content: center;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 20px;
}

.updates-row-inbox {
  display: flex;
  align-items: center;
  flex-direction: row;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
}

.photo-updates-inbox {
  width: 57px;
  height: 57px;
  border-radius: 50%;
  
  margin-left: 36px;
}

.name-updates-inbox {
  font-family: Graphik-Medium;
  font-size: 18px;
  margin-left: 14px;
}

.icon-updates-inbox {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #69AA0F;
  margin-left: 14px;
  margin-right: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  
}

.update-count-inbox {
  color: white;
  font-size: 12px;
  font-family: graphik-medium;
}

.category-divider-inbox {
  
  width: 90vw;
  margin-left: auto;
  padding-right: auto;
  height: 1px;
  border: 0;
  background-color: #CCCCCC;
}

.update-dropdown-inbox {
  display: flex;
  flex-direction: column;
  margin-top: 31px;
}

.dropdown-row-inbox {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-left: 39px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.leftside-dropdown-row-inbox {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.rightside-dropdown-row-inbox {
  margin-left: auto;
  margin-right: 33px;
}

.time-inbox {
  font-family: Graphik-Medium;
  font-size: 12px;
  color: #CCCCCC;

}

.message-inbox {
  font-family: Graphik-Regular;
  font-size: 14px;
  

}




.news-container-inbox {
  width: 90vw;
height: 25vh;
background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 6px #0000003B;
border-radius: 10px;
margin-left: auto;
margin-right: auto;
display: flex;
align-items: center;
flex-direction: row;
cursor: pointer;
margin-bottom: 20px;
overflow: auto;
padding: 0 1em 0 1.5em;
}

.news-leftside-inbox {
  /* margin-left: .8em; */
  width: 200px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 7px;
}


.large-text-news-inbox {
  width: 50vw;
  height: 63px;
  font-size: 1em;
  font-family: graphik-medium;
  line-height: 135%;
  overflow: hidden;
  /* padding-left: 1em; */
}
.small-text-news-inbox {
  width: 50vw;
  height: 60px;
  font-size: .8em;
  font-family: graphik-regular;
  line-height: 135%;
  display: flex;
  align-items: center;
  /* padding-left: 1em; */

}

.photo-news-inbox {
  width: 30vw;
  height: 30vw;
  background-color: grey;
  border-radius: 8px;
  margin-left: auto;
  /* margin-right: 10px; */
}



.switcher {
  display: inline-block;
  height: 30px;
  margin-top:3px;
  padding: 4px;
  background: #7676801F;
  border-radius: 2px;
  width: 350px;
  border-radius: 30px;
  
  position: relative;
}

.switcher__input {
  display: none;
}

.switcher__label {
  float: left;
  width: 50%;
  font-size: 13px;
  line-height: 30px;
  color: #000;
  text-align: center;
  cursor: pointer;
  position: inherit;
  z-index: 1;
  transition: color 0.2s cubic-bezier(0.4, 0.0, 0.2, 1);
  will-change: transform;
  font-family: graphik-medium;
}

.switcher__toggle {
  position: absolute;
  float: left;
  height: 30px;
  width: 50%;
  font-size: 12px;
  line-height: 30px;
  cursor: pointer;
  background-color: #FFFFFF;
  border-radius: 30px;
  left: 5px;
  top: 4px;
  transition: left 0.25s cubic-bezier(0.4, 0.0, 0.2, 1);
  will-change: transform;
}


.switcher__input--yang:checked ~ .switcher__toggle {
  left: 175px;
}

