.main-homepageNews {
margin-bottom: 100px;

}

.large-text-homepageNews {
  padding-left: 26px;
  color: #2436E7;
  font-size: 20px;
  font-family: graphik-medium;
  margin-bottom: 16px;
  text-align: left;
}

.content-container-homepageNews {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.photo-homepageNews {
  width: 90vw;
  height: 206px;
 
  border-radius: 10px;  
  margin-bottom: 22px;
}

.headline-homepageNews {
  font-size: 16px;
  font-family: graphik-medium;
  width: 85vw;
  margin-bottom: 12px;
}

.content-homepageNews {
  font-size: 14px;
  font-family: graphik-regular;
  width: 85vw;
  line-height: 150%;
  margin-bottom: 25px;
}