.main-7 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 926px;
  width: 100vw;
 
 
}

.content-7{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.small-text-3 {
  font-size: 26px;
  font-family: Roboto;
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-000000);
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  padding-top: 30px;
  padding-bottom: 30px;
}
.large-text-3 {
  font-size: 46px;
  font-family: Roboto;
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-000000);
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;

}

.button-text-3 {
  font-size: 26px;
  font-family: Roboto;
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-000000);
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  padding-top: 30px;
  padding-bottom: 30px;
}

.user-signup {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.text-field-7 {
  text-align: center;
  margin-bottom: 20px;
}

.button-container-7 {
  padding: 20px;
}
.accredited-investor-link-7 {
 color: blue;
 text-decoration: underline;
 font-size: 14px;
 font-family: graphik-regular;
}

.link-container-7 {
  text-align: center;
  margin-bottom: 10px;
}

.or-container-7 {
  padding-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #8DC63F;
  font-family: graphik-regular;
  font-size: 14px;
  gap: 12px;
  margin-bottom: 20px;
  
}

.or-container-7 hr{
 width: 150px;
 height: 4px;
 border: 0;
background-color: #8DC63F;
}

.sign-up-container-7 {
  
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.sign-up-items-7 {
  padding: 10px;
}

.sign-up-with-button-7 {
  cursor: pointer;
  border: none;
  background: none;
  width: 350px;
  max-width: 100%;
  height: 50px;
  /* UI Properties */
  border: 1px solid #A5A6A8;
  border-radius: 30px;
  opacity: 1;
  font-family: graphik-regular;
  font-size: 17px;
  white-space: nowrap;
  color: var(--unnamed-color-ffffff);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align:middle;
  /* font: normal normal medium 22px/22px Graphik; */
  letter-spacing: 0px;
  color: #C5C5C5;
  opacity: 1;
}
.button-text-7 {
  margin-left: auto;
}
.button-icon-7 {
  margin-top: 5px;
  margin-bottom: auto;
  margin-right: auto; 
  margin-left: 15px;
}



.small-text-7 {
  font-size: 18px;
  font-family: graphik-regular;
  margin-bottom: 20px; 
}

.dropdown-container-7 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}