.main-15 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  
  width: 100vw;
  margin: 0;
  padding: 0;
}
.text-15 {
  font-size: 18px;
  font-family: Graphik-medium;
  color: #2436e7;
  margin-top: 0;
}

.dropdown {
  width: 80vw;
  margin: 100 auto;
 
  position: relative;
  user-select: none;
  padding: 0;
  margin: 0;
  }
  
  
  .dropdown-button-active {
    padding: 5px 10px;
    background: #fff;
    height: 35px;
    
    border: 1px solid #C4C4C4;
    border-radius: 24px 24px 0px 0px;
    opacity: 1;
    color: #333;
    font-family: graphik-medium;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding-bottom: 0 !important;
  
  }
  
  .dropdown-arrow-active{
    border: solid black;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }
  .dropdown .dropdown-button-active {
  
    /* Layout Properties */
   padding: 10px 26px 26px 20px;
   
   /* margin-bottom: 30px; */
   background: #fff;
   border: 1px solid #C4C4C4;
   border-radius: 24px 24px 0px 0px;
   color: #C5C5C5;
   font-family: graphik-medium;
  
   font-size: 14px;
   display: flex;
   align-items: center;
   justify-content: space-between;
   cursor: pointer;
   }
  
  .dropdown .dropdown-button-selected {
  
   /* Layout Properties */
   padding: 16px 26px 16px 20px;
  background: #fff;
  border: 1px solid #C4C4C4;
  border-radius: 24px;
  /* height: 48px; */
  color: #333;
  font-family: graphik-medium;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  }
  
  .dropdown-arrow{
    border: solid black;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
  
  .dropdown .dropdown-content {
    position: absolute;
    top: 98%;
    width: 99.4%;
    height: 170px;
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 4px #00000014;
    border: 1px solid #C4C4C4;
    border-radius: 0px 0px 24px 24px;
    opacity: 1;
    overflow-y:auto;
    position: absolute;
  }
  
  .dropdown-item {
    height: 38px;
    font-size: 14px;
    font-family: graphik-medium;
    margin: 16px 0px 16px 20px;
    cursor: pointer;
  }
  
.text-field-15 {
  margin-top: 25px;
  margin-bottom: 25px;
}

.upload-file-15{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.upload-button-15 {
  width: 80vw;
  height: 39px;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #C4C4C4;
  border-radius: 24px;
  opacity: 1;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align:middle;
  cursor: pointer;
}

input[type=file]::upload-button-15 {
  width: 350px;
  height: 39px;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #C4C4C4;
  border-radius: 24px;
  opacity: 1;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align:middle;
  cursor: pointer;
}
.button-text-15 {
  font-size: 14px;
  font-family: Graphik-medium;
  margin-right: auto;
  margin-left: 20px;
}

.button-icon-15 {
font-size: 22px;
margin-right: 25px;
font-family: Graphik-regular;
margin-left: auto;
}

.social-accounts-15 {
  
margin-top: 50px;
margin-bottom: 10px;
font-family: Graphik-medium;
font-size: 14px;
letter-spacing: 0px;
color: #000000;
opacity: 1;
}

.TOS-text-15 {
  text-align: center;
  font-family: Graphik-regular;
  font-size: 10px;
  line-height: 130%;
  margin-top: 26px;
  padding-bottom: 168px;
}