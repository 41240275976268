.background-menu {
  width: 100vw;
  height: 100%;
  background-color: grey;
  

}

.menu-main {
  /* width: 300px; */
  height: 100vh;
  width: 70vw;
  background-color: #FFFFFF;
  box-shadow: 10px 3px 6px #00000014;
  display: flex;
  align-items: center;
  top: 0;
  bottom: 0;
  left: -100%;
  z-index: 1;
  transition: 0.5s;
  flex-direction: column;
 
  
}

.profile-photo-menu {
  margin-top: 10vh;
  height: 20vw;
  width: auto;
  
  border-radius: 50%;
  display: inline-block;
 
}

.exit-button-menu {
 
  font-size: 30px;
  position: fixed;
  font-family: graphik-regular;
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.username-menu {
  margin-top: 22.58px;
  font-size: 1.3em;
  font-family: graphik-regular;

}

.email-menu {
  margin-top: 11.58px;
  font-size: 1em;
  font-family: graphik-regular;
}

.link-container-menu {
  margin-top: 10vh;
  margin-bottom: 10vh;
}

.link-item-menu {
  margin-bottom: 2em;
  font-size: 1em;
  font-family: graphik-regular;
  text-align: center;
}




.shadow-md {
  box-shadow: 0px 5px 10px rgba(90, 116, 148, 0.3);
}
.navbar{
border-radius: 5px;
}
.sidebar{
  /* width: 70vw; */
  height: 100vh;
  box-shadow: 0px 4px 8px rgb(0 0 0 / 16%);
  background-color: #fff;
  position: absolute;
  top: 0;
  left: -100%;
  z-index: 2;
  transition: 0.5s;
}
.sidebar.active{
  position: fixed;
  left: 0;
}
.sd-header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
}
.sidebar-overlay{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.4);
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
}
.sidebar-overlay.active{
  opacity: 1;
  visibility: visible;
}
.sd-body{
  padding: 15px;
  max-height: calc(100vh - 67px);
  overflow-x: hidden;
}
.sd-body ul{
  display: inline-block;
  width: 100%;
  margin-bottom: 0;
  padding: 0;
}
.sd-body ul li{
  list-style: none;
  margin-bottom: 8px;
}
.sd-body ul li .sd-link{
  display: inline-block;
  width: 100%;
  padding: 10px 16px;
  color: #475f7b;
  background-color: #e5e8ec;
}