.year-chart-container {
  width: 100vw;
  
}

.select-chart-row {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 37px;
  font-family: graphik-medium;
  font-size: 12px;
}

.select-chart-row .selected-chart-name {
  color: #2436e7;
  cursor: pointer;
}
.selected-chart-name {

padding: 0;
}
.select-chart-row .chart-name {
  color: #cccccc;
  cursor: pointer;
}



.chart-hr {
  margin-left: 25px;
  padding-right: 25px;  
  width: 378px;
  height: 1px;
  border: 0;
  background-color: #cccccc;
  margin-bottom: -1px;
  padding: 0;
  
}
.selected-chart-hr {
  
  width: 30px;
  margin-top: 0px;
  margin-right: 123px;
  height: 1px;
  border: 0;
  background-color: #2436e7;
}

