
.filter-main {
  width: 100vw;
  /* height: 926px; */
  display: flex;
  align-items: center;
  flex-direction: column;
  
}
.filter-button {
  width: 121px;
height: 29px;
background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 6px #00000029;
border-radius: 7px;
display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  font-size: 14px;
  padding-left: 10px;
  font-family: graphik-regular;
  cursor: pointer;
}
.large-text-row-filter {
  display: flex;
  
  align-items: center;
  flex-direction: row;
  gap: 30vw;
  margin-left: 26px;
  margin-top: 99px;
  margin-bottom: 29px;
}
.exit-button-menu-filter {
  font-size: 45px;
  color: #CCCCCC;
  cursor: pointer;
  font-family: graphik-regular;
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  margin-right: 16px;
  margin-left: auto;
}
.large-text-filter {
  font-size: 1.5em;
  font-family: graphik-medium;
  margin-right: auto;

}

.filter-list {
  /* margin-left: 44.44px; */
  font-size: 17px;
  font-family: graphik-regular;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 0.6em;
}

.category-divider {
  
  width: 90vw;
  margin-left: auto;
  padding-right: auto;
  height: 1px;
  border: 0;
  background-color: #CCCCCC;
}
.checkbox-industry {
  width: 20px;
height: 20px;
/* UI Properties */
background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
background: #FFFFFF 0% 0% no-repeat padding-box;
border: 1px solid #D8D8D8;
border-radius: 50%;
}

.category-industry{
  width: 80vw;
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.category-name-industry{
  margin-right: auto;
  
}


.filter-checkbox {
  margin-left: auto;
  
}

input[type="checkbox"] {
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;

  color: #CCCCCC;
  width: 20px;
  height: 20px;
  border: 1px solid #CCCCCC;
  border-radius: 50%;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
  
}

input[type="checkbox"]::before {
  content: "";
  width: 20px;
  height: 20px;
  border-radius: 50%;
  /* transform: scale(0);
  transition: 120ms transform ease-in-out; */
  /* box-shadow: inset 1em 1em var(--form-control-color); */
}

input[type="checkbox"]:checked::before {
  background-image: url('../Media/blue_check (2).svg');
  /* background-color: #2436E7; */
  /* clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%); */
}

.hashtag-filter {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-left: 26px;
  margin-top: 14px;
  margin-bottom: 29px;
}
.hashtag-list-filter {
  font-family: graphik-regular;
  font-size: 17px;
}
.hashtag-container-filter {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap; 
  margin-left: 44px;
  margin-right: 44px;
  gap: 10px;
}

.bottom-buttons-filter {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.small-text-filter {
  font-size: 15px;
  font-family: graphik-regular;
  color: #2436e7;
  margin-top: 18px;
}

