.main-subscribeProfile {
  width: 100vw;

}


.background-subscribeProfile {
  width: 100vw;
background-image: linear-gradient(#42C0F04D, #FFFFFF22, #FFFFFF);
}

.user-profile-subscribeProfile {
  /* padding: 0 38px 0 38px; */
  width: 75vw;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.user-photo-subscribeProfile {
  margin-top: 10vh;
  height: 154px;
  width: 154px;
  
  border-radius: 50%;
  display: inline-block;
  background-color: #bbb;
}

.user-name-subscribeProfile {
  text-align: center;
  margin-top: 18px;
  font-size: 28px;
  font-family: graphik-medium;
  color: #2436e7;
}

.user-bio-subscribeProfile {
  margin-top: 25px;
  text-align: left;
  font-size: 14px;
  font-family: graphik-regular;
  line-height: 160%;
}

.strategy-subscribeProfile {
  /* padding: 38px; */
  width: 75vw;
  display: flex;
  margin-top: 10vh;
  flex-direction: column;
}
.strategy-big-text-subscribeProfile {
  text-align: left;
  font-size: 22px;
  font-family: graphik-medium;
}

.subscribe-button-subscribeProfile{
  width: 100vw;
  height: 1000px;
  display: flex;
  justify-content: center;
  background-color: #C5C5C5;
  
}