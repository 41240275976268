

.main-3 {
  width: 100vw;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.left-side-3 {
  
  width: 50vw;
  height: 100vh;
  position: sticky;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* overflow: hidden; */
}

.right-side-3 {
  /* background-color: blue; */
  background-color: #42c0f0;
  width: 50vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

}

.left-content-3 {
  width: 40vw;
  height: 70vh;
  padding: 1em;
  /* overflow: hidden; */
}
.logo-text-3 {
  font-size: 3em;
  color: #2436e7;
  font-family: graphik-semibold;
  margin-right: auto;
  margin-top: -15vh;
  /* margin-bottom: auto; */
  padding: .5em;
}
.small-text-3 {
  font-size: 26px;
  font-family: Roboto;
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-000000);
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  padding-top: 30px;
  padding-bottom: 30px;
}
.large-text-3 {
  font-size: 46px;
  font-family: Roboto;
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-000000);
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;

}

.button-text-3 {
  font-size: 26px;
  font-family: Roboto;
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-000000);
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  padding-top: 30px;
  padding-bottom: 30px;
}

.user-signup {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

/* .text-field {
  font-size: 40px !important;
} */

.button-container {
  padding: 20px;
}
.accredited-investor-link {
 color: blue;
 text-decoration: underline;
 font-size: 14px;
 font-family: graphik-regular;
}

.link-container {
  text-align: center;
}

.or-container {
  padding-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #8DC63F;
  font-family: graphik-regular;
  font-size: 14px;
  
}

.or-container hr{
 width: 190px;
 height: 4px;
 border: 0;
background-color: #8DC63F;
}

.sign-up-container {
  
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.sign-up-items {
  padding: 10px;
}

.sign-up-with-button {
  cursor: pointer;
  border: none;
  background: none;
  width: 510px;
  max-width: 100%;
  height: 50px;
  /* UI Properties */
  border: 1px solid #A5A6A8;
  border-radius: 30px;
  opacity: 1;
  font-family: graphik-regular;
  font-size: 17px;
  color: var(--unnamed-color-ffffff);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align:middle;
  /* font: normal normal medium 22px/22px Graphik; */
  letter-spacing: 0px;
  color: #C5C5C5;
  opacity: 1;
}
.button-text {
  margin-right: 175px;
}
.button-icon {
  margin-top: 5px;
  margin-bottom: auto;
  margin-right: auto; 
  margin-left: 15px;
}


.right-content-3{
 
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.right-text-3 {
  font-family: graphik-semibold;
  text-align: left;
  font-size: 40px;
  letter-spacing: -0.96px;
  color: #FFFFFF;
  opacity: 1;
}

.investor-profile-3 {
  
  margin-top: 1.5em;
  width: 30vw;
  height: 20vh;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0000003B;
  border-radius: 10px;
  opacity: 1;
  display: flex;
 
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.profile-photo-3 {
  width: 50px;
  height: 50px;
  margin-bottom: auto;
  margin-right: 1em;
  margin-top: 1em;
  margin-left: 1em;
  border-radius: 50%;
  /* display: inline-block; */
  background-color: #bbb;

}



.profile-text-3 {
  height: 15vh;
  
  /* margin-left: 15px; */
  margin-right: 15px;
  /* margin-left: 15px; */
  
  display: flex;
  align-items: left;
  justify-content: center;
  flex-direction: column;
  color: #000000;
  letter-spacing: 0px;
  line-height: 150%;
}

.profile-name-3 {
  font-family: graphik-medium;
  font-size: 1.5em;
}

.profile-stats-3 {
  font-family: graphik-medium;
  font-size: 1em;
}
.profile-bio-3 {
  font-family: graphik-regular;
  font-size: .8em;
}
.profile-tags-3 {
  font-family: graphik-regular;
  font-size: .8em;
}






.content-7{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.small-text-3 {
  font-size: 26px;
  font-family: Roboto;
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-000000);
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  padding-top: 30px;
  padding-bottom: 30px;
}
.large-text-3 {
  font-size: 46px;
  font-family: Roboto;
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-000000);
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;

}

.button-text-3 {
  font-size: 26px;
  font-family: Roboto;
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-000000);
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  padding-top: 30px;
  padding-bottom: 30px;
}

.user-signup {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.text-field-73 {
  text-align: center;
  margin-bottom: 1em;
}

.button-container-73 {
  padding: 1em;
}
.accredited-investor-link-73 {
 color: blue;
 text-decoration: underline;
 font-size: .8em;
 font-family: graphik-regular;
}

.link-container-73 {
  text-align: center;
  margin-bottom: 10px;
}

.or-container-73 {
  padding-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #8DC63F;
  font-family: graphik-regular;
  font-size: 14px;
  gap: 1px;
  margin-bottom: 20px;
  
}

.or-container-73 hr{
 width: 10vw;
 height: .25em;
 border: 0;
 margin: 1em;
background-color: #8DC63F;
}

.sign-up-container-73 {
  
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

}



.sign-up-with-button-73 {
  cursor: pointer;
  border: none;
  background: none;
  width: 30vw;
  max-width: 100%;
  height: 5vh;
  /* UI Properties */
  border: 1px solid #A5A6A8;
  border-radius: 30px;
  opacity: 1;
  font-family: graphik-regular;
  font-size: .75em;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;

  letter-spacing: 0px;
  color: #C5C5C5;
  opacity: 1;
  margin-bottom: 1.5em;
}
.button-text-73 {
  margin-left: auto;
  font-size: 1.5em;
}
.button-icon-73 {
  margin-top: auto;
  margin-bottom: auto;
  /* margin-right: auto;  */
  margin-left: 1em;
}



.small-text-73 {
  font-size: 18px;
  font-family: graphik-regular;
  margin-bottom: 20px; 
}

.dropdown-container-73 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}