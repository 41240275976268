.main-investor {
  width: 100vw;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  
}

.background-investor {
width: 100vw;
background-image: linear-gradient(#42C0F04D, #FFFFFF22, #FFFFFF);

}


.user-photo-investor {
  margin-top: 91px;
  height: 154px;
  width: 154px;
  
  border-radius: 50%;
  display: flex;
  background-color: #bbb;

  margin-right: auto;
  margin-left: auto;
}

.user-name-investor {
  text-align: center;
  margin-top: 18px;
  font-size: 28px;
  font-family: graphik-medium;
  color: #2436e7;
}

.user-bio-investor {
  padding: 0 1.5em 0 1.5em ;
  margin-top: 25px;
  text-align: left;
  font-size: 14px;
  font-family: graphik-regular;
  line-height: 160%;
}

.strategy-investor {
  /* padding: 38px; */
  width: 80vw;
  display: flex;
  margin-top: 47px;
  flex-direction: column;
}
.strategy-big-text-investor {
  text-align: left;
  font-size: 1.5em;
  font-family: graphik-medium;
}

.strategy-text-investor {
  line-height: 170%;
  margin-top: 33px;
  text-align: left;
  font-size: 14px;
  font-family: graphik-regular;
}

.strategy-divider-investor {
  margin-top: 30px;
  margin-bottom: 30px;
  width: 100px;
  height: 1px;
  border: 0;
  background-color: black;
}

.chart-investor {

  display: flex;
  align-items: center;
  flex-direction: column;
}

.risk-chart-investor {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 48px;
}

.chart-key-investor {
  margin-top: 15px;
  
  text-align: left;
  font-size: 14px;
  font-family: graphik-regular;
  display: flex;
  align-items: center;
  
}

.chart-key-blue-ball {
  width: 11px;
height: 11px;
margin-left: 1.5em !important;
margin-right: 8px;
/* UI Properties */
background: var(--unnamed-color-2436e7) 0% 0% no-repeat padding-box;
background: #2436E7 0% 0% no-repeat padding-box;
opacity: 1;
border-radius: 50%;
}

.chart-key-sp500{
  margin-top: 8px;
  
  text-align: left;
  font-size: 14px;
  font-family: graphik-regular;
  display: flex;
  align-items: center;
  
}

.chart-key-green-ball {
  width: 11px;
height: 11px;
margin-left: 1.5em !important;
margin-right: 8px;
/* UI Properties */
background: var(--unnamed-color-8dc63f) 0% 0% no-repeat padding-box;
background: #8dc63f 0% 0% no-repeat padding-box;
opacity: 1;
border-radius: 50%;
}

.back-arrow-myinvestors {
  width: 50px;
  height: 50px;
  margin-bottom: -50px;
  padding: 0;
  cursor: pointer;
  z-index: 1;
}