.filter-button {
  width: 121px;
height: 29px;
background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 6px #00000029;
border-radius: 7px;
}



.filter-menu{
  width: 100vw;
  min-height: 100vh;
  box-shadow: 0px 4px 8px rgb(0 0 0 / 16%);
  background-color: #fff;
  position: absolute;
  top: 0;
  right: -100%;
  z-index: 1;
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
}
.filter-menu.active{
  right: 0;
  opacity: 1;
  visibility: visible;
}
.sd-header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
}
.sidebar-overlay{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.4);
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
}
.sidebar-overlay.active{
  opacity: 1;
  visibility: visible;
}
.sd-body{
  padding: 15px;
  max-height: calc(100vh - 67px);
  overflow-x: hidden;
}
.sd-body ul{
  display: inline-block;
  width: 100%;
  margin-bottom: 0;
  padding: 0;
}
.sd-body ul li{
  list-style: none;
  margin-bottom: 8px;
}
.sd-body ul li .sd-link{
  display: inline-block;
  width: 100%;
  padding: 10px 16px;
  color: #475f7b;
  background-color: #e5e8ec;
}