.main-subscribe1{
  width: 100vw;
  height: 100%;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  flex-direction: column;
  padding-bottom: 50px;
}

.text-subscribe1 {
  font-size: 16px;
  font-family: graphik-medium;
  text-align: center;
  line-height: 140%;
  margin-top: 18px;
}

.textbox-subscribe1 {
  width: 275px;
}

.subscription-container-subscribe1 {
  margin-top: 30px;
  width: 350px;
  height: 153px;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0000003B;
  border-radius: 10px;
  opacity: 1;
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.price-subscribe1 {
  font-size: 40px;
  font-family: graphik-semibold;
}

.subscription-type-subscribe1 {
  font-size: 16px;
  font-family: graphik-semibold;
}

.subscription-conditions-subscribe1 {
  font-size: 12px;
  font-family: graphik-regular;
}