.main-myinvestors {
  width: 100vw;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 2em;
}

.header-row-myinvestors {
  display: flex;
  flex-direction: row;

  margin-top: 40px;
  
  margin-bottom: 31px;
}

.large-text-myinvestors {
  font-size: 1.5em;
  font-family: graphik-medium;
  margin-right: 55vw;
  margin-left: 0.8em;
}

.row-container-myinvestors {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  
  margin-bottom: 1em;
}

.delete-dot-column-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 3px;
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
}

.delete-dot {
 width: 6px;
 height: 6px;
 border-radius: 50%;
 background-color: #CCCCCC;
}


.container-myinvestors {
 

 
  width: 90vw;
  height: 83px;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0000003B;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  transition: 0.5s ;
  
}

.container-myinvestors-active {
 
   
  width: 80vw;
  height: 5em;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0000003B;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  transition: 0.5s ;
  margin-left: 1.4em;
 

}

.delete-button-container {
  width: 73px;
  height: 83px;
  background: #D50F0F 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0000003B;
  border-radius: 10px 0 0 10px;
  margin-left: 310px;
  /* top: 0;
  bottom: 0; */
  right: -100%;
  position: fixed;
  transition: 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;


}

.delete-button-container.active{
  position: fixed;
  right: 0;
  transition: 0.5s;

  
  
 
}


.color-circle-myinvestors {
  width: 57px;
  height: 57px;
  margin-left: 11px;
  margin-right: auto;
/* UI Properties */
  background: #714595 0% 0% no-repeat padding-box;
  border-radius: 32px;

}

.color-circle-myinvestors-active {
  width: 57px;
  height: 57px;
  margin-left: 11px;
  margin-right: auto;
/* UI Properties */
  background: light-grey 0% 0% no-repeat padding-box;
  border-radius: 32px;

}

.content-myinvestors {
  display: flex;
  
  
  flex-direction: column;
  width: 75%;
  display: flex;
  
  justify-content: center;
  flex-direction: column;
  gap: 7px;
}

.text-myinvestors{
  margin-left: 14px;
  display: flex;
  align-items: center;
  
  font-size: 14px;
  font-family: graphik-medium;
  flex-direction: row;
  gap: 10px;
}

.name-myinvestors {
  margin-left: 14px;
  font-size: 18px;
  font-family: graphik-medium;
}


