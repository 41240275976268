.main-browse-investors {
  width: 100vw;
  
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  
}
.big-text-browse-investors {
  margin: 3vh auto 0 26px;
  font-size: 22px;
  font-family: graphik-medium;
}

.select-filter-browse {
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin-top: 36px;
  gap: 5vw;
}



.dropdown-browse {
  width: 25vw;
  /* margin: 100 auto; */
  position: relative;
  user-select: none;
  padding: 0;
  margin: 0;
  }
  
  
  .dropdown-button-active-browse {
    padding: 5px 10px;
    background: #fff;
    height: 29px;
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 7px 7px 0px 0px;
    color: #333;
    font-family: graphik-regular;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding-bottom: 0 !important;
  
  }
  
  .dropdown-arrow-active-browse{
    border: solid #CCCCCC;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }
  .dropdown-browse .dropdown-button-active-browse {
    padding: 0px 12px 12px 12px;
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 7px 7px 0px 0px;
    font-family: graphik-regular;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
   }
  
  .dropdown-browse .dropdown-button-selected-browse {

    padding: 8px 12px 8px 12px;
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 7px;
    color: #333;
    font-family: graphik-regular;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  
  }
  
  .dropdown-arrow-browse{
    border: solid #CCCCCC;
    border-width: 0 1.33px 1.33px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
  
  .dropdown-browse .dropdown-content-browse {
    position: absolute;
    top: 98%;
    width: 100%;
    height: 170px;
    background: #FFFFFF;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 0px 0px 7px 7px;
    overflow: scroll;
    z-index: 1;
  }
  ::-webkit-scrollbar {
    width: 0;  
    background: transparent; 
    position: absolute; 
  }
  .dropdown-item-browse {
    height: 28px;
    font-size: 14px;
    font-family: graphik-regular;
    margin: 16px 0px 0px 20px;
    cursor: pointer;
    
    
  }
  
  .selected-categories-browse {
    margin-top: 20px;
    width: 90vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap; 
    
  }

  .selected-category-browse {
    margin: 5px;
    min-width: 20vw;
   
    border: solid #C5C5C5;
    color: #C5C5C5;
    font-family: graphik-regular;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    
  }
  .selected-category-name-browse {
    font-size: 13px;
    margin-left: 24px;
    margin-right: 14px;
  }

  .delete-category-browse {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    font-size: 30px;
    margin-right: 10px;
    cursor: pointer;
    
  }

  .investor-profile-investors {
  
    margin-top: 30px;
    width: 90vw;
    min-height: 20vh;
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #0000003B;
    border-radius: 10px;
    opacity: 1;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    padding: 2vh 0 2vh 0;
  }
  
  .profile-photo-investors {
   
    width: 12vw;
    height: auto;
    margin-bottom: auto;
    margin-left: 13px;
    margin-top: auto;
   
    border-radius: 50%;
    display: inline-block;
    /* border: solid white; */
  
  }

  .main-subscribeProfile {
    width: 100vw;
   
  }
  
  
  .background-subscribeProfile {
  width: 100vw;
  background-image: linear-gradient(#42C0F04D, #FFFFFF22, #FFFFFF);
  display: flex;
  
  align-items: center;

  flex-direction: column;
  }
  
  .user-profile-subscribeProfile {
    /* padding: 0 38px 0 38px; */
    
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .user-photo-subscribeProfile {
    margin-top: 91px;
    height: 154px;
    width: 154px;
    
    border-radius: 50%;
    display: inline-block;
    background-color: #bbb;
  }
  
  .user-name-subscribeProfile {
    text-align: center;
    margin-top: 18px;
    font-size: 28px;
    font-family: graphik-medium;
    color: #2436e7;
  }
  
  .user-bio-subscribeProfile {
    margin-top: 25px;
    text-align: left;
    font-size: 14px;
    font-family: graphik-regular;
    line-height: 160%;
  }
  
  .strategy-subscribeProfile {
    padding-left: 2em;
    padding-top: 2em;
    width: 85vw;
    display: flex;
    margin-top: 47px;
    flex-direction: column;
  }
  .strategy-big-text-subscribeProfile {
    text-align: left;
    font-size: 22px;
    font-family: graphik-medium;
  }
  
  .subscribe-button-subscribeProfile{
    width: 100vw;
    height: 1000px;
    display: flex;
    justify-content: center;
    background-color: lightgrey;
    
  }

  .main-subscribe1{
    width: 100vw;
    height: 926px;
    
    display: flex;
    align-items: center;
    /* justify-content: center; */
    flex-direction: column;
  }
  
  .text-subscribe1 {
    font-size: 16px;
    font-family: graphik-medium;
    text-align: center;
    line-height: 140%;
    margin-top: 18px;
  }
  
  .textbox-subscribe1 {
    width: 275px;
  }
  
  .subscription-container-subscribe1 {
    margin-top: 30px;
    width: 85vw;
    height: 153px;
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #0000003B;
    border-radius: 10px;
    opacity: 1;
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  
  .price-subscribe1 {
    font-size: 40px;
    font-family: graphik-semibold;
  }
  
  .subscription-type-subscribe1 {
    font-size: 16px;
    font-family: graphik-semibold;
  }
  
  .subscription-conditions-subscribe1 {
    font-size: 12px;
    font-family: graphik-regular;
  }

  .main-subscribe2{
    width: 100vw;
    height: 935px;
  
    display: flex;
    
    flex-direction: column;
  }
  
  .payment-info-subscribe2{
    margin-top: 15vh;
  }
  
  .large-text-subscribe2 {
    font-size: 1.5em;
    font-family: graphik-medium;
    margin-left: 1em;
  }
  .investor-profile-background-subscribe2{
    margin-top: 28px;
    width: 100%;
    background-color: #F9F9F9;
    height: 141px;
    display: flex;
  
    align-items: center;
    justify-content: center;
  }
  
  .investor-container-subscribe2 {
   
    width: 90vw;
    height: 97px;
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #0000003B;
    border-radius: 10px;
    opacity: 1;
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: left;
    flex-direction: row;
  }
  
  .investor-photo-subscribe2{
    height: 57px;
    width: 57px;
    margin-left: 10px; 
    border-radius: 50%;
    display: inline-block;
    background-color: #bbb;
  }
  
  .investor-name-subscribe2 {
    font-size: 18px;
    font-family: graphik-medium;
    margin-right: auto;
    margin-bottom: auto;
    margin-top: 30px;
    
  }
  
  .payment-method-container-subscribe2 {
    margin-top: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    
  }
  .small-payment-text-subscribe2{
    font-size: 13px;
    font-family: graphik-regular;
    margin-bottom: 12px;
  }
  
  .payment-methods-subscribe2 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 17px;
  }
  
  .option-container-subscribe2 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
  }
  
  .option-box-subscribe2 {
    width: 82px;
    height: 50px;
    border: solid #D8D8D8;
    border-radius: 10px;
   text-align: center;
  }
  
  .card-info-container-subscribe2{
    width: 343px;
    height: 152px;
    
    display: flex;
    gap: 15px;
    justify-content: center;
    flex-direction: column;
    margin: 20px auto 20px auto;
  }
  
  .card-row-subscribe2{
    display: flex;
   
    flex-direction: row;
  }
  
  .text-card-info-subscribe2 {
    margin-right: auto;
    font-size: 17px;
    font-family: graphik-medium;
  }
  
  .card-info-divider {
    
    width: 343px;
    margin: 0;
    padding: 0;
    height: 1px;
    border: 0;
    background-color: #CCCCCC;
  }
  .large-subscribe2 {
    width: 190px;
    font-family: graphik-regular;
    font-size: 17px;
    color: #8A8A8F;
    border: none;
    
  }
  
  .small-input-subscribe2 {
    width: 56px;
    font-family: graphik-regular;
    font-size: 17px;
    color: #8A8A8F;
    border: none;
  
  }
  
  .cvc-info-subscribe2 {
    width: 56px;
    font-family: graphik-regular;
    font-size: 17px;
    
    border: none;
  }
  
  .save-info-subscribe2 {
    margin-left: 45px;
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 8px;
  }
  
  .text-save-info-subscribe2{
    font-size: 15px;
    font-family: graphik-regular;
  }
  
  .total-subscribe2 {
    width: 343px;
    height: 86px;
    
    display: flex;
    gap: 15px;
    justify-content: center;
    flex-direction: column;
    margin: 20px auto 20px auto;
  }
  
  .row-total-subscribe2 {
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  
  .subtotal-text-subscribe2{
    font-size: 17px;
    font-family: graphik-regular;
  }
  
  .price-subscribe2 {
    margin-left: auto;
    font-size: 17px;
    font-family: graphik-regular;
  }
  
  .total-text-subscribe2{
    font-size: 17px;
    font-family: graphik-bold;
  }
  
  .total-price-subscribe2 {
    margin-left: auto;
    font-size: 17px;
    font-family: graphik-bold;
  }
  
  .button-container-subscribe2 {
    margin-top: 40px;
    display: flex;
    justify-content: center;
  }
  
  .investor-container-browse {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 50px;
    height: 500px;
    width: 100%;
    overflow-y: scroll;
  }