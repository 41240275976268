.year-chart-container {
  width: 100vw;
  
}

.select-chart-row-week {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 37px;
  font-family: graphik-medium;
  font-size: 12px;
}

.select-chart-row-week .selected-chart-name-week {
  color: #2436e7;
  cursor: pointer;
}
.selected-chart-name-week {

padding: 0;
}
.select-chart-row-week .chart-name-week {
  color: #cccccc;
  cursor: pointer;
}



.chart-hr-week {
  margin-left: 25px;
padding-right: 25px;  
  width: 378px;
  height: 1px;
  border: 0;
  background-color: #cccccc;
  margin-bottom: -1px;
  padding: 0;
  
}
.selected-chart-hr-week {
  
  width: 35px;
  margin-top: 0px;
  margin-right: 260px;
  height: 1px;
  border: 0;
  background-color: #2436e7;
}

