

.navbar-container {
  width: 100vw;
  
 
  
margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.menu-icon {
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 6px;
  cursor: pointer;
  
}
.icon-bar {
  width: 15px;
  height: 1px;
  border: 0;
  background-color: black;
 
}
.icon-bar-white {
  width: 15px;
  height: 1px;
  background-color: white;
  margin-top: 3px;
  margin-bottom: 3px;
}

.notification-icon {
  margin-left: auto;
  /* margin-right: 15px; */
  cursor: pointer;
 
}
