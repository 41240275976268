.main-homepage {
  width: 100vw;
  /* height: 100%; */
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
}

.top-logo-homepage {
  width: 100%;
  height: 80px;
  margin: 0;
  padding: 0;
}



.chart-background-homepage {
  
  width: 100vw;
  background-color: #2436E7; 
  margin: 0;
  padding: 0;
}

.user-name-homepage {
  text-align: center;
  margin-top: 18px;
  font-size: 28px;
  font-family: graphik-medium;
  color: #2436e7;
}

.user-bio-homepage {
  margin-top: 25px;
  text-align: left;
  font-size: 14px;
  font-family: graphik-regular;
  line-height: 160%;
}

.strategy-homepage {
  padding: 38px;
  width: 85vw;
  display: flex;
  margin-top: 47px;
  flex-direction: column;
}
.strategy-big-text-homepage {
  text-align: left;
  font-size: 22px;
  font-family: graphik-medium;
}

.strategy-text-homepage {
  line-height: 170%;
  margin-top: 33px;
  text-align: left;
  font-size: 14px;
  font-family: graphik-regular;
}

.strategy-divider-homepage {
  margin-top: 30px;
  margin-bottom: 30px;
  width: 100px;
  height: 1px;
  border: 0;
  background-color: black;
}

.chart-homepage {

  display: flex;
  align-items: center;
  flex-direction: column;
}

.risk-chart-homepage {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 48px;
}

.chart-key-investor-homepage {
  margin-top: 14px;
  color: #FFFFFF;
  /* background-color: #2436E7;  */
  text-align: left;
  font-size: 14px;
  font-family: graphik-regular;
  display: flex;
  align-items: center;
  
}

.chart-key-blue-ball-homepage {
  width: 11px;
height: 11px;
margin-left: 18px;
margin-right: 8px;
/* UI Properties */
background: var(--unnamed-color-8dc63f) 0% 0% no-repeat padding-box;
background: #FFFFFF 0% 0% no-repeat padding-box;
opacity: 1;
border-radius: 50%;
}

.chart-key-sp500-homepage{
  margin-top: 8px;
  color: #FFFFFF;
  text-align: left;
  font-size: 14px;
  font-family: graphik-regular;
  display: flex;
  align-items: center;
  
}

.chart-key-green-ball-homepage {
  width: 11px;
height: 11px;
margin-left: 18px;
margin-right: 8px;
/* UI Properties */
background: var(--unnamed-color-8dc63f) 0% 0% no-repeat padding-box;
background: #8dc63f 0% 0% no-repeat padding-box;
opacity: 1;
border-radius: 50%;
}

.blume-logo-homepage {
 
  font-family: graphik-semibold;
  margin: 0px 0px 0px 18px;
  padding: 0;
  color: white;
  font-size: 30px;
}