.main-mystocks {
  width: 100vw;
  height: 989px;
  
}

.header-row-mystocks {
  display: flex;
  flex-direction: row;
  margin-left: 35px;
  margin-top: 3vh;
  margin-bottom: 31px;
}

.large-text-mystocks {
  font-size: 22px;
  font-family: graphik-medium;
}

.row-mystocks {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.myRow-container-mystocks {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.row-container-mystocks {
  display: flex;
  align-items: center;
  flex-direction: row;
  
}

.delete-dot-column-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 3px;
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
}

.delete-dot {
 width: 6px;
 height: 6px;
 border-radius: 50%;
 background-color: #CCCCCC;
}


.container-mystocks {
 

 
  width: 90vw;
  height: 83px;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0000003B;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  transition: 0.5s ;
  
}

.container-mystocks-active {
 
   
  width: 306px;
  height: 83px;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0000003B;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  transition: 0.5s ;

}

.delete-button-container-mystocks {
  
  height: 83px;
  background: #D50F0F 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0000003B;
  border-radius: 10px 0 0 10px;
  
  /* top: 0;
  bottom: 0; */
  float: right;
  display: none;

  
  
  
}

.delete-button-container-mystocks.active{
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 83px;
  float: right;
  left: 0;
  transition: 0.5s;
 
  
  margin-left: 10px;
}


.color-circle-mystocks {
  width: 57px;
  height: 57px;
  margin-left: 11px;
  margin-right: auto;
/* UI Properties */
  background: #714595 0% 0% no-repeat padding-box;
  border-radius: 32px;

}

.color-circle-mystocks-active {
  width: 57px;
  height: 57px;
  margin-left: 11px;
  margin-right: auto;
/* UI Properties */
  background: #714595 0% 0% no-repeat padding-box;
  border-radius: 32px;

}

.content-mystocks {
  display: flex;
  
  
  flex-direction: column;
  width: 75%;
  display: flex;
  
  justify-content: center;
  flex-direction: column;
  gap: 7px;
}

.text-mystocks{
  margin-left: 14px;
  display: flex;
  align-items: center;
  
  font-size: 14px;
  font-family: graphik-medium;
  flex-direction: row;
  gap: 10px;
}

.name-mystocks {
  margin-left: 14px;
  font-size: 18px;
  font-family: graphik-medium;
}


.remove-dialog-mystocks {
  width: 315px;
  height: 292px;
  background: #2436E7 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0000007B;
  border-radius: 42px;
  display: flex;
  align-items: center;
  
  flex-direction: column;
  color: white;
}

.x-dialog-mystocks {
 margin-left: auto;
 margin-right: 35.5px;
 margin-top: 15px;
 cursor: pointer;
 
}

.large-text-dialog-mystocks {
  font-size: 28px;
  font-family: graphik-medium;
  text-align: center;
  line-height: 140%;
  margin-bottom: 30px;
}

.small-text-dialog-mystocks {
  font-size: 14px;
  font-family: graphik-regular;
  text-decoration: underline;
  cursor: pointer;
  
}
.back-button-container-stockpage{
  margin: 5vh auto auto 5vw;
}



/* 
const MyStocksRow =  stockData.map(stock => {
  return (

      <div className='row-container-mystocks'>

        <div onClick={ToggleDelete} className='delete-dot-column-container'>
          <div className='delete-dot'></div>
          <div className='delete-dot'></div>
          <div className='delete-dot'></div>
        </div>

       
        <div style={{ cursor: 'pointer' }} className={` ${isOpen == true ? 'container-mystocks-active' : 'container-mystocks'}`}
      onClick={() => {setSelectedStock(stock.symbol); ToggleStockPage();}}
      
    >
      
<div  className='color-circle-most-active'></div>
<div className='content-most-active'>
<div className='text-most-active'>
<div className='name-most-active'>{capitalizeFirstLetter(stock.name)}</div>
<div className='share-price-most-active'>{stock.price}</div>
</div>
<div className='text-most-active'>
<div className='ticker-most-active'>{stock.symbol.toUpperCase()}</div>
<div className='percentage-most-active'>+4.76 (0.48%)</div>
</div>

</div>
</div> 
      

        <div className={`delete-button-container ${isOpen == true ? 'active' : ''}`}>
                    <div  className='exit-button-menu' onClick={ToggleDelete}></div>          
                    <div className='delete-button'><DeleteOutlineIcon sx={{ textAlign: 'center'}} /> </div>
          </div>

      </div>  

)
}) */