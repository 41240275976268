.main-17 {
  height: 110%;
  width: 100vw;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  
}

.content-17 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 353px;
}

.large-text-17 {
  margin-top: 89px;
  font-size: 28px;
  font-family: Graphik-bold;
  text-align: left;
  margin-right: auto;
  line-height: 130%;
}

.divider-17 {
  width: 353px;
  height: 1px;
  border: 0;
  margin-top: 37px;
  margin-bottom: 3px;
 background-color: #8DC63F;
}

.investor-profile-17 {
  
  margin-top: 30px;
  width: 378px;
  height: 153px;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0000003B;
  border-radius: 10px;
  opacity: 1;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.investor-profile-17-active {
  
  margin-top: 30px;
  width: 378px;
  height: 153px;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0000003B;
  border-radius: 10px;
  opacity: 1;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  border: solid blue;
}

.profile-photo-17 {
 
  
  height: 57px;
  width: 57px;
  margin-bottom: auto;
  margin-left: 11px;
  margin-top: 11px;
  padding-right: 11px;
  border-radius: 50%;
  display: inline-block;
  

}



.profile-text-17 {
  height: 122px;
  width: 100%;
  /* margin-left: 15px; */
  margin-right: 11px;
  margin-left: 11px;
  
  display: flex;
  align-items: left;
  justify-content: center;
  flex-direction: column;
  color: #000000;
  letter-spacing: 0px;
  line-height: 150%;
}

.profile-name-17 {
  font-family: graphik-medium;
  font-size: 18px;
}

.profile-stats-17 {
  font-family: graphik-medium;
  font-size: 14px;
}
.profile-bio-17 {
  font-family: graphik-regular;
  font-size: 14px;
}
.profile-tags-17 {
  font-family: graphik-regular;
  font-size: 14px;
}

.random-button-17 {
  font-family: graphik-regular;
  font-size: 14px;
  text-align: center;
  text-decoration: underline;
  color: #2436e7;
}

.selected-investor-17 {
  margin-top: 20px;
  font-size: 18px;
  font-family: graphik-medium;
}
.logo-box-17 {
  width: 70px;
  /* padding: 100px 20px 170px auto; */
/* margin-bottom: -100px; */
margin: 0 0px -165px auto;
  
}
.row-investorSelect {
  display: flex;
  align-items: center;
  
  flex-direction: row;
}


.main-investorSelect {
  width: 428px;
  height: 100%;
  /* background-color: lightgrey; */


}

.background-investorSelect {
width: 428px;
background-image: linear-gradient(#42C0F04D, #FFFFFF22, #FFFFFF);
}

.user-profile-investorSelect {
  padding: 90px 38px 0 38px;
  width: 355px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.user-photo-investorSelect {
  margin-top: 91px;
  height: 154px;
  width: 154px;
  
  border-radius: 50%;
  display: inline-block;
  background-color: #bbb;
}

.user-name-investorSelect {
  text-align: center;
  margin-top: 18px;
  font-size: 28px;
  font-family: graphik-medium;
  color: #2436e7;
}

.user-bio-investorSelect {
  margin-top: 25px;
  text-align: left;
  font-size: 14px;
  font-family: graphik-regular;
  line-height: 160%;
}

.strategy-investorSelect {
  padding: 38px;
  width: 355px;
  display: flex;
  margin-top: 47px;
  flex-direction: column;
}
.strategy-big-text-investorSelect {
  text-align: left;
  font-size: 22px;
  font-family: graphik-medium;
}

.strategy-text-investorSelect {
  line-height: 170%;
  margin-top: 33px;
  text-align: left;
  font-size: 14px;
  font-family: graphik-regular;
}

.strategy-divider-investorSelect {
  margin-top: 30px;
  margin-bottom: 30px;
  width: 100px;
  height: 1px;
  border: 0;
  background-color: black;
}

.chart-investorSelect {

  display: flex;
  align-items: center;
  flex-direction: column;
}

.risk-chart-investorSelect {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 48px;
}

.chart-key-investorSelect {
  margin-top: 15px;
  
  text-align: left;
  font-size: 14px;
  font-family: graphik-regular;
  display: flex;
  align-items: center;
  
}

.chart-key-blue-ball {
  width: 11px;
height: 11px;
margin-left: 39px;
margin-right: 8px;
/* UI Properties */
background: var(--unnamed-color-2436e7) 0% 0% no-repeat padding-box;
background: #2436E7 0% 0% no-repeat padding-box;
opacity: 1;
border-radius: 50%;
}

.chart-key-sp500{
  margin-top: 8px;
  
  text-align: left;
  font-size: 14px;
  font-family: graphik-regular;
  display: flex;
  align-items: center;
  
}

.chart-key-green-ball {
  width: 11px;
height: 11px;
margin-left: 39px;
margin-right: 8px;
/* UI Properties */
background: var(--unnamed-color-8dc63f) 0% 0% no-repeat padding-box;
background: #8dc63f 0% 0% no-repeat padding-box;
opacity: 1;
border-radius: 50%;
}