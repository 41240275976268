
.main-browsestocks {
  width: 100vw;
  height: 926px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.large-text-browsestocks{
  font-size: 22px;
  font-family: graphik-medium;
  margin: 3vh auto 21px 35px;
}

.searchbar-browsestocks {
  width: 90vw;
  height: 48px;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #C4C4C4;
  border-radius: 24px;
  display: flex;
  align-items: center;
  color: #C5C5C5;
  flex-direction: row;
  margin-bottom: 37px;
}

.input-searchbar-browsestocks{
  margin-left: 15px;
  border: none;
  font-size: 14px;
  font-family: graphik-medium;
  margin-right: auto;
  color: #C5C5C5;
  
}

.icon-searchbar {
  margin-right: 10vw;
}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #C5C5C5;
  opacity: 1; /* Firefox */
}

.logo-circle-stockpage {
  height: 154px;
  width: 154px;
  
  border-radius: 50%;
  display: inline-block;
  background-color: #bbb;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;

}



.main-stockpage {
  width: 100vw;
  height: 1291px;
  
 
}

.upper-stockpage {
  background-image: linear-gradient(#42C0F04D, #FFFFFF22, #FFFFFF);
  height: 236px;
  width: 100vw;
  display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
  
}
.icon-stockpage { 
  width: 154px;
  height: 154px;
  border-radius: 50%;
  
}
.filler-icon-stockpage { 
  width: 200px;
  height: 200px;
  border-radius: 50%;
  
}
.lower-stockpage {
width: 100vw;
height: 500px;
/* border: solid; */
display: flex;

align-items: center;
flex-direction: column;
}

.name-stockpage {
  margin-top: 18px;
  color: #2436e7;
  font-size: 28px;
  font-family: graphik-medium;
  margin-bottom: 12px;
}

.todays-movement-stockpage {
 color: #8dc63f;
 font-size: 18px;
 font-family: graphik-medium;
 margin-bottom: 12px;
}

.movement-time-stockpage {
  font-size: 14px;
  font-family: graphik-regular;
  margin-bottom: 20px;
}

.about-stockpage {
  margin-left: 36.5px;
  margin-right: 36.5px;
  line-height: 150%;
  font-size: 14px;
  font-family: graphik-regular;
}

.button-row-stockpage {
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 60vw;
  margin-bottom: -50px;

}

.back-button-stockpage {
  height: 50px;
  width: 50px;
  cursor: pointer;
}
.add-button-stockpage {
  height: 50px;
  width: 50px;
  transform: rotate(-45deg);
  cursor: pointer;
}
.color-circle-browse {
  width: 45px;
  height: 45px;
  margin-left: .5em;
  margin-right: auto;
/* UI Properties */
 
  border-radius: 32px;

}

.stocks-container-browse {
 
  margin-bottom: 35px;
  margin-left: 25px;
  margin-right: 25px;
  width: 90vw;
  height: 83px;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0000003B;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
 
}
.myRow-container-browse{
  height: 80vh;
  overflow-y: scroll;
  
}