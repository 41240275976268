.industry-container {
 
  margin-bottom: 35px;
  margin-left: auto;
  margin-right: auto;
  width: 90vw;
  height: 83px;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0000003B;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.industry-color-circle-myinvestors {
  width: 2em;
  height: 2em;
  margin-left: 1em;
  margin-right: .2em;
/* UI Properties */
  /* background: #714595 0% 0% no-repeat padding-box; */
  border-radius: 32px;

}

.industry-content {
  display: flex;
  
  
  flex-direction: column;
  width: 315px;
  display: flex;
  
  justify-content: center;
  flex-direction: column;
  gap: 15px;
}

.industry-text {
  display: flex;
  align-items: center;
  /* justify-content: center; */
 
  flex-direction: row;
}

.industry-name {
  margin-left: 17px;
  font-size: 16px;
  font-family: graphik-medium;
}

.industry-percentage {
  font-size: 14px;
  font-family: graphik-medium;
  color: #CCCCCC;
  margin-left: auto;
  margin-right: 32px;
}

.industry-percentage-track {
  width: 280px;
height: 8px;
/* UI Properties */
background: #EAEAEA 0% 0% no-repeat padding-box;
box-shadow: 0px 1px 0px #FFFFFF0B;
border-radius: 18px;
opacity: 1;
}

.industry-percentage-bar {

height: 8px;
/* UI Properties */
background: #714595 0% 0% no-repeat padding-box;
border-radius: 18px;
opacity: 1;
}

.industry-main-selected {
  width: 428px;
  height: 120%;
  background: #EEEEEE 0% 0% no-repeat padding-box;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  margin-right: auto;
  margin-left: auto;
}

.industry-container-selected {
  width: 378px;
  min-height: 420px;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0000003B;
  border-radius: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 20px;
}
.top-row-industry-container-selected {
  margin-top: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.bottom-row-industry-container-selected {
  margin-top: 25px;
  margin-bottom: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

/* .company-row-industry-container-selected{

} */
.name-column-industry-container-selected {
  margin-left: 17px;
  display: flex;
  
  justify-content: center;
  flex-direction: column;
}

.industry-name-selected {
  font-size: 16px;
  font-family: graphik-medium;
}

.industry-ticker-selected {
  font-size: 14px;
  font-family: graphik-regular;
}
.chart-industry-container-selected {
  margin-top: 40px;
  margin-bottom: 130px;
  /* width: 165px;
  height: 165px;
  background-color: grey; */
  border-radius: 50%;
}