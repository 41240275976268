.main-leaderboard {
  margin-top: 38px;
  
  
}

.text-leaderboard {
  padding-left: 26px;
  color: #2436E7;
  font-size: 20px;
  font-family: graphik-medium;
  margin-bottom: 16px;

}

.select-filter-leaderboard {
  display: flex;
 
  justify-content: center;
  flex-direction: row;
  gap: 8px;
}

.top-three-leaderboard {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.investor-profile-leaderboard {
  
  margin-top: 30px;
  width: 90vw;
  height: 100%;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0000003B;
  border-radius: 10px;
  opacity: 1;
  display: flex;
 
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.profile-photo-leaderboard {
 
  
  height: 57px;
  width: 57px;
  margin-bottom: auto;
  margin-left: 11px;
  margin-top: 11px;
  padding-left: 11px;
  border-radius: 50%;
  display: inline-block;
  background-color: #bbb;

}



.profile-text-leaderboard {
  height: 122px;
  width: 100%;
  /* margin-left: 15px; */
  margin-right: 11px;
  margin-left: 11px;
  
  display: flex;
  align-items: left;
  justify-content: center;
  flex-direction: column;
  color: #000000;
  letter-spacing: 0px;
  line-height: 150%;
}

.profile-name-leaderboard {
  font-family: graphik-medium;
  font-size: 1.3em;
}

.profile-stats-leaderboard {
  font-family: graphik-medium;
  font-size: .8em;
}
.profile-bio-leaderboard {
  font-family: graphik-regular;
  font-size: .8em;
}
.profile-tags-leaderboard {
  font-family: graphik-regular;
  font-size: .8em;
}
