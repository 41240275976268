.year-chart-container-hp {
  width: 100vw;
  
}

.select-chart-row-all-hp {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 37px;
  font-family: graphik-medium;
  font-size: 12px;
}

.select-chart-row-all-hp .selected-chart-name-all-hp {
  color: #42C0F0;
  cursor: pointer;
}
.selected-chart-name-all-hp {

padding: 0;
}
.select-chart-row-all-hp .chart-name-all-hp {
  color: #cccccc;
  cursor: pointer;
}



.chart-hr-all-hp {
  /* margin-left: 25px;
padding-right: 25px;   */
  /* width: 378px; */
  width: 80vw;
  height: 1px;
  border: 0;
  background-color: #000000;
  margin-bottom: -1px;
  padding: 0;
  
}
.selected-chart-hr-all-hp {
  
  width: 20px;
  margin-top: 0px;
  margin-right: 72px;
  height: 1px;
  border: 0;
  background-color: #42C0F0;
  transition: 0.5s;
}

